import { Injectable } from '@angular/core';
import { AuthorizationService } from '../authorization.service';
import { CategoriesTypesService } from '../categories-types/categories-types.service';
import { Observable } from 'rxjs';
import { GirCatalogResource } from 'src/app/shared/interfaces/gir-catalog.resource';

export interface Pin {
  id: number,
  name: string,
  created_at: string
}

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {

  /**
   * Api resource.
   *
   * @private
   * @type {string}
   * @memberof CatalogsService
   */
  private resource: string = 'catalogs';

  /**
   * Creates an instance of CatalogsService.
   * 
   * @param {AuthorizationService} http
   * @param {CategoriesTypesService} categoriesType
   * @memberof CatalogsService
   */
  constructor(
    private http: AuthorizationService,
    private categoriesType: CategoriesTypesService
  ) { }

  /**
   * Find all catalogs.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='Catalogs.name']
   * @param {string} [order='asc']
   * @param {number} pageNumber
   * @param {number} [pageSize=10]
   * @returns {Observable<any>}
   * @memberof CatalogsService
   */
  findAll(filters: Object = {}, sort: string = 'Catalogs.name', order: string = 'asc', pageNumber: number, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }

  /**
   * Find all products for gir catalog.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='Catalogs.name']
   * @param {string} [order='asc']
   * @param {number} pageNumber
   * @param {number} [pageSize=10]
   * @returns {Observable<any>}
   * @memberof CatalogsService
   */
  findAllGirCatalog(): Observable<GirCatalogResource> {
    return this.http.get(`/${this.resource}/gir-catalog`);
  }

  /**
   * Find all gifts for the given catalog.
   *
   * @param {number} id
   * @param {number} [maxPoints]
   * @returns {Observable<any>}
   * @memberof CatalogsService
   */
  findAllGifts(id: number, maxPoints?: number): Observable<any> {
    let params = '&include=pictures,gifts_attributes_values';

    if (maxPoints) {
      params += `&filter[Gifts.required_points][max]=${maxPoints}&filter[Gifts.stock][min]=1`;
    }

    return this.http.get(`/${this.resource}/${id}/gifts`, this.http.buildListQueryString({}, 'Gifts.name', 'asc', 0, 1000) + params);
  }
  findAllGiftsSelection(id: number, maxPoints?: number): Observable<any> {
    let params = '&include=pictures,gifts_attributes_values';
    if (maxPoints) {
      params += `&filter[Gifts.required_points][max]=${maxPoints}`;
    }

    return this.http.get(`/${this.resource}/${id}/gifts`, this.http.buildListQueryString({}, 'Gifts.name', 'asc', 0, 1000) + params);
  }

  /**
   * Get catalogs for selection.
   *
   * @returns
   * @memberof CatalogsService
   */
  getForSelection(): Observable<any> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString({}, 'Catalogs.name', 'asc', 0, 5000));
  }

  /**
   * Find a single item.
   *
   * @param {number} id
   * @returns {Observable<any>}
   * @memberof CatalogsService
   */
  findOne(id: number): Observable<any> {
    return this.http.get(`/${this.resource}/${id}`);
  }

  /**
   * Store catalog data.
   *
   * @param {Pin} data
   * @returns {Observable<any>}
   * @memberof CatalogsService
   */
  store(data: Pin): Observable<any> {
    if (data.id) {
      return this.http.put(`/${this.resource}/${data.id}`, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }

  /**
   * Delete the given ids.
   *
   * @param {*} ids
   * @returns
   * @memberof CatalogsService
   */
  delete(ids) {
    return this.http.delete(`/${this.resource}/${ids.toString()}`);
  }
}
