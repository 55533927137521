import { Injectable } from '@angular/core';
import { AuthorizationService } from '../authorization.service';

@Injectable({
  providedIn: 'root'
})
export class ScopesService {


  /**
   * Scope id for admin area.
   *
   * @type {number}
   * @memberof ScopesService
   */
  public SCOPE_ADMIN: number = 1;

  /**
   * Scope id for web area.
   *
   * @type {number}
   * @memberof ScopesService
   */
  public SCOPE_WEB: number = 2;


  /**
   * Scope id for salesman area.
   * 
   * @type {number}
   * @memberof ScopesService
   */
  public SCOPE_SALESMAN: number = 3;


  /**
   * Resource name.
   *
   * @type {string}
   * @private
   * @memberof ScopesService
   */
  private resource: string = 'scopes';

  /**
 * Creates an instance of RolesService.
 * @param {AuthorizationService} http
 * @memberof RolesService
 */
  constructor(private http: AuthorizationService) { }


  /**
   * Find items.
   *
   * @param {Object} [filters={}]
   * @param {string} [sort='Scopes.name']
   * @param {string} [order='asc']
   * @param {number} pageNumber
   * @param {number} [pageSize=1000]
   * @returns
   * @memberof ScopesService
   */
  findAll(filters: Object = {}, sort: string = 'Scopes.name', order: string = 'asc', pageNumber: number, pageSize: number = 1000) {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }



  /**
   * Find a single item.
   *
   * @param {number} id
   * @returns
   * @memberof ScopesService
   */
  findOne(id: number) {
    return this.http.get(`/${this.resource}/${id}`);
  }
}
