import { Injectable } from '@angular/core';
import { AuthorizationService } from '../authorization.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesRejectionReasonsService {

  /**
   * Api resource
   *
   * @private
   * @type {string}
   * @memberof RolesService
   */
  private resource: string = 'sales-rejection-reasons';

  /**
   * Creates an instance of RolesService.
   * 
   * @param {AuthorizationService} http
   * @memberof RolesService
   */
  constructor(
    private http: AuthorizationService
  ) { }

  /**
   * List items.
   *
   * @returns
   * @memberof RolesService
   */
  findAll(filters = {}, sort: string = 'SalesRejectionReasons.name', order: string = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<any> {
    return this.http.get(`/${this.resource}`, this.http.buildListQueryString(filters, sort, order, pageNumber, pageSize));
  }

  /**
   * Get for selection.
   *
   * @param {*} [filters={}]
   * @returns {Observable<any>}
   * @memberof SalesRejectionReasonsService
   */
  getForSelection(filters = {}): Observable<any> {
    return this.findAll(filters, 'SalesRejectionReasons.name', 'asc', 0, 5000);
  }

  /**
   * Find an item.
   *
   * @param {number} id
   * @returns
   * @memberof RolesService
   */
  findOne(id: number, params?): Observable<any> {
    return this.http.get(`/${this.resource}/${id}`, this.http.parseParams(params));
  }

  /**
   * Stores an item
   *
   * @param {Role} data
   * @returns
   * @memberof RolesService
   */
  store(data: any): Observable<any> {
    if (data.id) {
      return this.http.put(`/${this.resource}/${data.id}`, data);
    } else {
      return this.http.post(`/${this.resource}`, data);
    }
  }

  /**
   * Delete one or multiple items.
   * 
   * @param ids 
   */
  delete(ids): Observable<any> {
    return this.http.delete(`/${this.resource}/${ids.toString()}`);
  }
}
