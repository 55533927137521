import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeliveryTypesService {


  /**
   * Only to inbox.
   *
   * @memberof DeliveryTypesService
   */
  public TYPE_PUSH = 1;

  /**
   * Inbox and email.
   *
   * @memberof DeliveryTypesService
   */
  public TYPE_EMAIL = 2;

  /**
   * Inbox and SMS.
   *
   * @memberof DeliveryTypesService
   */
  public TYPE_SMS = 3;

  constructor() { }

  /**
   * Get all delivery types.
   *
   * @returns
   * @memberof DeliveryTypesService
   */
  getDeliveryTypes() {
    return [
      {
        id: this.TYPE_PUSH,
        name: 'DELIVERY_TYPE_PUSH'
      },
      {
        id: this.TYPE_EMAIL,
        name: 'DELIVERY_TYPE_EMAIL'
      }
    ];
  }
}
